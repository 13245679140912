import { IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image"
import React, { VFC } from "react"
import "../../../styles/top.css"
import { getBgImage, Target } from "../../../utils"

type Props = {
  logo: IGatsbyImageData
  target: Target
}

const OpeningShutter: VFC<Props> = ({ logo, target }) => {
  return (
    <div className={`shutter ${getBgImage(target)}`}>
      <div className="logo">
        <GatsbyImage image={logo} alt="logo" />
      </div>
    </div>
  )
}

export default OpeningShutter
