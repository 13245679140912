import React from "react"

import Layout from "../components/top/Layout"
import SEO from "../components/Seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout isTop={false}>
    <SEO title="404: Not found" />
    <div className="h-64 mt-20 text-center">
      <h1>ページが見つかりません。</h1>
      <Link
        to="/"
        className="block mt-10 text-lg font-bold underline hover:no-underline"
      >
        ホームへ戻る
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
