import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Footer = () => {
  const links = [
    { text: "noone", path: "/noone" },
    { text: "nico", path: "/nico" },
    { text: "Do", path: "/do" },
    { text: "Do CAFE", path: "/do-cafe" },
  ]
  return (
    <footer>
      <div className="h-4 mt-20 bg-repeat-x md:mt-32 md:h-8 bg-topWaveSp md:bg-topWave "></div>
      <div className="relative bg-top bg-repeat-round">
        <div className="mx-auto max-w-[960px] pt-12 px-4">
          <div className="flex justify-center">
            <StaticImage
              src="../../images/top/noone.png"
              alt="logo"
              layout="fixed"
              width={100}
              className="flex-shrink-0"
            />
            <div className="pb-16 ml-8 md:ml-20">
              <div className="mb-4 text-xl font-kiwiMaru">株式会社NOONE</div>
              <div>〒360-0162 埼玉県熊谷市村岡469番地（コンビニ裏）</div>
              <div>TEL: 048-598-4012</div>
            </div>
          </div>
          <div className="absolute -translate-x-1/2 bottom-4 left-1/2">
            © {new Date().getFullYear()} NOONE Inc.
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
