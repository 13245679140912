/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./Header"
import Footer from "./Footer"
import "../../styles/top.css"
import { getImage } from "gatsby-plugin-image"
import OpeningShutter from "../shared/top/OpeningShutter"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  query {
    logo: file(relativePath: { eq: "top/noone.png" }) {
      childImageSharp {
        gatsbyImageData(width: 100)
      }
    }
  }
`

const Layout = ({ children, isTop = true }) => {
  const data = useStaticQuery(query)
  return (
    <>
      {/* 404ページならシャッターを表示しない  */}
      {isTop && <OpeningShutter logo={getImage(data.logo)} target="top" />}
      <div className="img"></div>
      <Header />
      <div className="mx-auto max-w-[960px] px-4">
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
